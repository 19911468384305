document.addEventListener('DOMContentLoaded', () => {
  const lazyLoadVideos = document.querySelectorAll('video.ibiza-lazy-video')
  lazyLoadVideos.forEach((videoEl) => {
    const loadSources = () => {
      const sources = videoEl.getElementsByTagName('source')
      for (const sourceEl of sources) {
        sourceEl.src = sourceEl.dataset.src
        sourceEl.removeAttribute('data-src')
      }

      // force video element to load now we have set its sources
      videoEl.load()
    }

    const maybeLazyLoadSources = () => {
      const isDesktopOnly = videoEl.classList.contains('desktop-only')
      const mqMobile = window.matchMedia('screen and (max-width: 767px)')

      // #165075: some videos should only load on desktop
      if (!isDesktopOnly || !mqMobile.matches) {
        // try to lazy load sources when video element enters viewport if IntersectionObserver is supported...
        if ('IntersectionObserver' in window) {
          const observer = new IntersectionObserver((entries, observer) => {
            const entry = entries[0]
            if (entry.isIntersecting) {
              loadSources(videoEl)
              observer.disconnect()
            }
          })

          observer.observe(videoEl)
        } else {
          // ... or load sources right away
          loadSources(videoEl)
        }
      }
    }

    maybeLazyLoadSources(videoEl)
  })
})
