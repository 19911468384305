// Custom Select
let a
let b
let c
let i
let j
let selElmnt
let x
/* Look for any elements with the class "custom-select": */
x = document.getElementsByClassName('ibiza-custom-select')
for (i = 0; i < x.length; i++) {
  selElmnt = x[i].getElementsByTagName('select')[0]
  /* For each element, create a new DIV that will act as the selected item: */
  a = document.createElement('DIV')
  a.classList.add('select-selected')
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML
  if (selElmnt.selectedIndex > 0) {
    a.classList.add('select-active')
  }
  x[i].appendChild(a)
  /* For each element, create a new DIV that will contain the option list: */
  b = document.createElement('DIV')
  b.setAttribute('class', 'select-items select-hide')
  for (j = 1; j < selElmnt.length; j++) {
    /* For each option in the original select element,
    create a new DIV that will act as an option item: */
    c = document.createElement('DIV')
    c.innerHTML = selElmnt.options[j].innerHTML
    if (selElmnt.selectedIndex == j) {
      c.setAttribute('class', 'same-as-selected')
    }
    c.addEventListener('click', function (e) {
      /* When an item is clicked, update the original select box,
      and the selected item: */
      let h
      let i
      let k
      let s
      let y
      s = this.parentNode.parentNode.getElementsByTagName('select')[0]
      h = this.parentNode.previousSibling
      for (i = 0; i < s.length; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i
          $(s).trigger('change')
          h.innerHTML = this.innerHTML
          h.classList.add('select-active')
          y = this.parentNode.getElementsByClassName('same-as-selected')
          for (k = 0; k < y.length; k++) {
            y[k].removeAttribute('class')
          }
          this.setAttribute('class', 'same-as-selected')
          break
        }
      }
      h.click()
    })
    b.appendChild(c)
  }
  x[i].appendChild(b)
  a.addEventListener('click', function (e) {
    /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
    e.stopPropagation()
    closeAllSelect(this)
    this.nextSibling.classList.toggle('select-hide')
    this.classList.toggle('select-arrow-active')
    if (!this.nextSibling.classList.contains('select-hide')) {
      const openEvent = new CustomEvent('openSelect')
      this.dispatchEvent(openEvent)
    } else {
      const closeEvent = new CustomEvent('closeSelect')
      this.dispatchEvent(closeEvent)
    }
  })
}

document.addEventListener('click', closeAllSelect)

function closeAllSelect(elmnt) {
  /* A function that will close all select boxes in the document,
  except the current select box: */
  let x
  let y
  let i
  const
    arrNo = []
  x = document.getElementsByClassName('select-items')
  y = document.getElementsByClassName('select-selected')
  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove('select-arrow-active')
    }
  }
  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add('select-hide')
    }
  }
}
